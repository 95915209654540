const MapServiceProviders = [
  {
    id: "OS",
    gpText: "Ordnance Survey",
    osText: "Ordnance Survey",
  },
  {
    id: "viaEuropa",
    gpText: undefined,
    osText: "viaEuropa",
  },
  {
    id: "thinkWare",
    gpText: "thinkWhere",
    osText: "thinkWhere",
  },
];

export default MapServiceProviders;
