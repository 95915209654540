import { adsDarkBlue } from "../utils/ADSColours";

const BLPUClassification = [
  {
    id: "C",
    lookup: "Commercial",
    display: "Commercial",
    colour: adsDarkBlue,
  },
  {
    id: "CA",
    lookup: "Commercial Agricultural",
    display: "Commercial Agricultural",
    colour: adsDarkBlue,
  },
  {
    id: "CA01",
    lookup:
      "Commercial Agricultural Farm / Non-Residential Associated Building",
    display: "Farm / Non-Residential Associated Building",
    colour: adsDarkBlue,
  },
  {
    id: "CA02",
    lookup: "Commercial Agricultural Fishery",
    display: "Fishery",
    colour: adsDarkBlue,
  },
  {
    id: "CA03",
    lookup: "Commercial Agricultural Horticulture",
    display: "Horticulture",
    colour: adsDarkBlue,
  },
  {
    id: "CA04",
    lookup: "Commercial Agricultural Slaughter House / Abattoir",
    display: "Slaughter House / Abattoir",
    colour: adsDarkBlue,
  },
  {
    id: "CB",
    lookup: "Commercial Ancillary Building",
    display: "Commercial Ancillary Building",
    colour: adsDarkBlue,
  },
  {
    id: "CC",
    lookup: "Commercial Community Services",
    display: "Community Services",
    colour: adsDarkBlue,
  },
  {
    id: "CC02",
    lookup: "Commercial Community Services Law Court",
    display: "Law Court",
    colour: adsDarkBlue,
  },
  {
    id: "CC03",
    lookup: "Commercial Community Services Prison",
    display: "Prison",
    colour: adsDarkBlue,
  },
  {
    id: "CC04",
    lookup:
      "Commercial Community Services Public / Village Hall / Other Community Facility",
    display: "Public / Village Hall / Other Community Facility",
    colour: adsDarkBlue,
  },
  {
    id: "CC05",
    lookup: "Commercial Community Services Public Convenience",
    display: "Public Convenience",
    colour: adsDarkBlue,
  },
  {
    id: "CC06",
    lookup:
      "Commercial Community Services Cemetery / Crematorium / Graveyard. In Current Use",
    display: "Cemetery / Crematorium / Graveyard. In Current Use",
    colour: adsDarkBlue,
  },
  {
    id: "CC07",
    lookup:
      "Commercial Community Services Church Hall / Religious Meeting Place / Hall",
    display: "Church Hall / Religious Meeting Place / Hall",
    colour: adsDarkBlue,
  },
  {
    id: "CC08",
    lookup: "Commercial Community Services Community Service Centre / Office",
    display: "Community Service Centre / Office",
    colour: adsDarkBlue,
  },
  {
    id: "CC09",
    lookup:
      "Commercial Community Services Public Household Waste Recycling Centre (HWRC)",
    display: "Public Household Waste Recycling Centre (HWRC)",
    colour: adsDarkBlue,
  },
  {
    id: "CC10",
    lookup: "Commercial Community Services Recycling Site",
    display: "Recycling Site",
    colour: adsDarkBlue,
  },
  {
    id: "CC11",
    lookup: "Commercial Community Services CCTV",
    display: "CCTV",
    colour: adsDarkBlue,
  },
  {
    id: "CC12",
    lookup: "Commercial Community Services Job Centre",
    display: "Job Centre",
    colour: adsDarkBlue,
  },
  {
    id: "CE",
    lookup: "Commercial Education",
    display: "Education",
    colour: adsDarkBlue,
  },
  {
    id: "CE01",
    lookup: "Commercial Education College",
    display: "College",
    colour: adsDarkBlue,
  },
  {
    id: "CE02",
    lookup: "Commercial Education Children’s Nursery / Crèche",
    display: "Children’s Nursery / Crèche",
    colour: adsDarkBlue,
  },
  {
    id: "CE03",
    lookup:
      "Commercial Education Preparatory / First / Primary / Infant / Junior / Middle School",
    display: "Preparatory / First / Primary / Infant / Junior / Middle School",
    colour: adsDarkBlue,
  },
  {
    id: "CE04",
    lookup: "Commercial Education Secondary / High School",
    display: "Secondary / High School",
    colour: adsDarkBlue,
  },
  {
    id: "CE05",
    lookup: "Commercial Education University",
    display: "University",
    colour: adsDarkBlue,
  },
  {
    id: "CE06",
    lookup: "Commercial Education Special Needs Establishment",
    display: "Special Needs Establishment",
    colour: adsDarkBlue,
  },
  {
    id: "CE07",
    lookup: "Commercial Education Other Educational Establishment",
    display: "Other Educational Establishment",
    colour: adsDarkBlue,
  },
  {
    id: "CH",
    lookup: "Commercial Hotel / Motel / Boarding / Guest House",
    display: "Hotel / Motel / Boarding / Guest House",
    colour: adsDarkBlue,
  },
  {
    id: "CH01",
    lookup:
      "Commercial Hotel / Motel / Boarding / Guest House Boarding / Guest House / Bed And Breakfast / Youth Hostel",
    display: "Boarding / Guest House / Bed And Breakfast / Youth Hostel",
    colour: adsDarkBlue,
  },
  {
    id: "CH02",
    lookup:
      "Commercial Hotel / Motel / Boarding / Guest House Holiday Let / Accommodation / Short-Term Let Other Than CH01",
    display: "Holiday Let / Accommodation / Short-Term Let Other Than CH01",
    colour: adsDarkBlue,
  },
  {
    id: "CH03",
    lookup: "Commercial Hotel / Motel / Boarding / Guest House Hotel / Motel",
    display: "Hotel / Motel",
    colour: adsDarkBlue,
  },
  {
    id: "CI",
    lookup: "Commercial Industrial",
    display: "Industrial",
    colour: adsDarkBlue,
  },
  {
    id: "CI01",
    lookup: "Commercial Industrial Factory / Manufacturing",
    display: "Factory / Manufacturing",
    colour: adsDarkBlue,
  },
  {
    id: "CI02",
    lookup: "Commercial Industrial Mineral / Ore Working / Quarry / Mine",
    display: "Mineral / Ore Working / Quarry / Mine",
    colour: adsDarkBlue,
  },
  {
    id: "CI03",
    lookup: "Commercial Industrial Workshop / Light Industrial",
    display: "Workshop / Light Industrial",
    colour: adsDarkBlue,
  },
  {
    id: "CI04",
    lookup: "Commercial Industrial Warehouse / Store / Storage Depot",
    display: "Warehouse / Store / Storage Depot",
    colour: adsDarkBlue,
  },
  {
    id: "CI05",
    lookup: "Commercial Industrial Wholesale Distribution",
    display: "Wholesale Distribution",
    colour: adsDarkBlue,
  },
  {
    id: "CI06",
    lookup: "Commercial Industrial Recycling Plant",
    display: "Recycling Plant",
    colour: adsDarkBlue,
  },
  {
    id: "CI07",
    lookup: "Commercial Industrial Incinerator / Waste Transfer Station",
    display: "Incinerator / Waste Transfer Station",
    colour: adsDarkBlue,
  },
  {
    id: "CI08",
    lookup: "Commercial Industrial Maintenance Depot",
    display: "Maintenance Depot",
    colour: adsDarkBlue,
  },
  {
    id: "CL",
    lookup: "Commercial Leisure",
    display: "Leisure",
    colour: adsDarkBlue,
  },
  {
    id: "CL01",
    lookup: "Commercial Leisure Amusements",
    display: "Amusements",
    colour: adsDarkBlue,
  },
  {
    id: "CL02",
    lookup: "Commercial Leisure Holiday / Campsite",
    display: "Holiday / Campsite",
    colour: adsDarkBlue,
  },
  {
    id: "CL03",
    lookup: "Commercial Leisure Library",
    display: "Library",
    colour: adsDarkBlue,
  },
  {
    id: "CL04",
    lookup: "Commercial Leisure Museum / Gallery",
    display: "Museum / Gallery",
    colour: adsDarkBlue,
  },
  {
    id: "CL06",
    lookup:
      "Commercial Leisure Indoor / Outdoor Leisure / Sporting Activity/ Centre",
    display: "Indoor / Outdoor Leisure / Sporting Activity/ Centre",
    colour: adsDarkBlue,
  },
  {
    id: "CL07",
    lookup:
      "Commercial Leisure Bingo Hall / Cinema / Conference / Exhibition Centre / Theatre / Concert Hall",
    display:
      "Bingo Hall / Cinema / Conference / Exhibition Centre / Theatre / Concert Hall",
    colour: adsDarkBlue,
  },
  {
    id: "CL08",
    lookup: "Commercial Leisure Zoo / Theme Park",
    display: "Zoo / Theme Park",
    colour: adsDarkBlue,
  },
  {
    id: "CL09",
    lookup:
      "Commercial Leisure Beach Hut (Recreational, Non-Residential Use Only)",
    display: "Beach Hut (Recreational, Non-Residential Use Only)",
    colour: adsDarkBlue,
  },
  {
    id: "CL10",
    lookup: "Commercial Leisure Licensed Private Members’ Club",
    display: "Licensed Private Members’ Club",
    colour: adsDarkBlue,
  },
  {
    id: "CL11",
    lookup: "Commercial Leisure Arena / Stadium",
    display: "Arena / Stadium",
    colour: adsDarkBlue,
  },
  {
    id: "CM",
    lookup: "Commercial Medical",
    display: "Medical",
    colour: adsDarkBlue,
  },
  {
    id: "CM01",
    lookup: "Commercial Medical Dentist",
    display: "Dentist",
    colour: adsDarkBlue,
  },
  {
    id: "CM02",
    lookup: "Commercial Medical General Practice Surgery / Clinic",
    display: "General Practice Surgery / Clinic",
    colour: adsDarkBlue,
  },
  {
    id: "CM03",
    lookup: "Commercial Medical Hospital / Hospice",
    display: "Hospital / Hospice",
    colour: adsDarkBlue,
  },
  {
    id: "CM04",
    lookup: "Commercial Medical Medical / Testing / Research Laboratory",
    display: "Medical / Testing / Research Laboratory",
    colour: adsDarkBlue,
  },
  {
    id: "CM05",
    lookup: "Commercial Medical Professional Medical Service",
    display: "Professional Medical Service",
    colour: adsDarkBlue,
  },
  {
    id: "CN",
    lookup: "Commercial Animal Centre",
    display: "Animal Centre",
    colour: adsDarkBlue,
  },
  {
    id: "CN01",
    lookup: "Commercial Animal Centre Cattery / Kennel",
    display: "Cattery / Kennel",
    colour: adsDarkBlue,
  },
  {
    id: "CN02",
    lookup: "Commercial Animal Centre Animal Services",
    display: "Animal Services",
    colour: adsDarkBlue,
  },
  {
    id: "CN03",
    lookup: "Commercial Animal Centre Equestrian",
    display: "Equestrian",
    colour: adsDarkBlue,
  },
  {
    id: "CN04",
    lookup: "Commercial Animal Centre Vet / Animal Medical Treatment",
    display: "Vet / Animal Medical Treatment",
    colour: adsDarkBlue,
  },
  {
    id: "CN05",
    lookup: "Commercial Animal Centre Animal / Bird / Marine Sanctuary",
    display: "Animal / Bird / Marine Sanctuary",
    colour: adsDarkBlue,
  },
  {
    id: "CO",
    lookup: "Commercial Office",
    display: "Office",
    colour: adsDarkBlue,
  },
  {
    id: "CO01",
    lookup: "Commercial Office Office / Work Studio",
    display: "Office / Work Studio",
    colour: adsDarkBlue,
  },
  {
    id: "CO02",
    lookup: "Commercial Office Broadcasting (TV / Radio)",
    display: "Broadcasting (TV / Radio)",
    colour: adsDarkBlue,
  },
  {
    id: "CR",
    lookup: "Commercial Retail",
    display: "Retail",
    colour: adsDarkBlue,
  },
  {
    id: "CR01",
    lookup: "Commercial Retail Bank / Financial Service",
    display: "Bank / Financial Service",
    colour: adsDarkBlue,
  },
  {
    id: "CR02",
    lookup: "Commercial Retail Retail Service Agent",
    display: "Retail Service Agent",
    colour: adsDarkBlue,
  },
  {
    id: "CR04",
    lookup: "Commercial Retail Market (Indoor / Outdoor)",
    display: "Market (Indoor / Outdoor)",
    colour: adsDarkBlue,
  },
  {
    id: "CR05",
    lookup: "Commercial Retail Petrol Filling Station",
    display: "Petrol Filling Station",
    colour: adsDarkBlue,
  },
  {
    id: "CR06",
    lookup: "Commercial Retail Public House / Bar / Nightclub",
    display: "Public House / Bar / Nightclub",
    colour: adsDarkBlue,
  },
  {
    id: "CR07",
    lookup: "Commercial Retail Restaurant / Cafeteria",
    display: "Restaurant / Cafeteria",
    colour: adsDarkBlue,
  },
  {
    id: "CR08",
    lookup: "Commercial Retail Shop / Showroom",
    display: "Shop / Showroom",
    colour: adsDarkBlue,
  },
  {
    id: "CR09",
    lookup: "Commercial Retail Other Licensed Premise / Vendor",
    display: "Other Licensed Premise / Vendor",
    colour: adsDarkBlue,
  },
  {
    id: "CR10",
    lookup: "Commercial Retail Fast Food Outlet / Takeaway (Hot / Cold)",
    display: "Fast Food Outlet / Takeaway (Hot / Cold)",
    colour: adsDarkBlue,
  },
  {
    id: "CR11",
    lookup: "Commercial Retail Automated Teller Machine (ATM)",
    display: "Automated Teller Machine (ATM)",
    colour: adsDarkBlue,
  },
  {
    id: "CS",
    lookup: "Commercial Storage Land",
    display: "Storage Land",
    colour: adsDarkBlue,
  },
  {
    id: "CS01",
    lookup: "Commercial Storage Land General Storage Land",
    display: "General Storage Land",
    colour: adsDarkBlue,
  },
  {
    id: "CS02",
    lookup: "Commercial Storage Land Builders’ Yard",
    display: "Builders’ Yard",
    colour: adsDarkBlue,
  },
  {
    id: "CT",
    lookup: "Commercial Transport",
    display: "Transport",
    colour: adsDarkBlue,
  },
  {
    id: "CT01",
    lookup:
      "Commercial Transport Airfield / Airstrip / Airport / Air Transport Infrastructure Facility",
    display:
      "Airfield / Airstrip / Airport / Air Transport Infrastructure Facility",
    colour: adsDarkBlue,
  },
  {
    id: "CT02",
    lookup: "Commercial Transport Bus Shelter",
    display: "Bus Shelter",
    colour: adsDarkBlue,
  },
  {
    id: "CT03",
    lookup:
      "Commercial Transport Car / Coach / Commercial Vehicle / Taxi Parking / Park And Ride Site",
    display:
      "Car / Coach / Commercial Vehicle / Taxi Parking / Park And Ride Site",
    colour: adsDarkBlue,
  },
  {
    id: "CT04",
    lookup: "Commercial Transport Goods Freight Handling / Terminal",
    display: "Goods Freight Handling / Terminal",
    colour: adsDarkBlue,
  },
  {
    id: "CT05",
    lookup: "Commercial Transport Marina",
    display: "Marina",
    colour: adsDarkBlue,
  },
  {
    id: "CT06",
    lookup: "Commercial Transport Mooring",
    display: "Mooring",
    colour: adsDarkBlue,
  },
  {
    id: "CT07",
    lookup: "Commercial Transport Railway Asset",
    display: "Railway Asset",
    colour: adsDarkBlue,
  },
  {
    id: "CT08",
    lookup: "Commercial Transport Station / Interchange / Terminal / Halt",
    display: "Station / Interchange / Terminal / Halt",
    colour: adsDarkBlue,
  },
  {
    id: "CT09",
    lookup: "Commercial Transport Transport Track / Way",
    display: "Transport Track / Way",
    colour: adsDarkBlue,
  },
  {
    id: "CT10",
    lookup: "Commercial Transport Vehicle Storage",
    display: "Vehicle Storage",
    colour: adsDarkBlue,
  },
  {
    id: "CT11",
    lookup: "Commercial Transport Transport Related Infrastructure",
    display: "Transport Related Infrastructure",
    colour: adsDarkBlue,
  },
  {
    id: "CT12",
    lookup: "Commercial Transport Overnight Lorry Park",
    display: "Overnight Lorry Park",
    colour: adsDarkBlue,
  },
  {
    id: "CT13",
    lookup:
      "Commercial Transport Harbour / Port / Dock / Dockyard / Slipway / Landing Stage / Pier / Jetty / Pontoon / Terminal / Berthing / Quay",
    display:
      "Harbour / Port / Dock / Dockyard / Slipway / Landing Stage / Pier / Jetty / Pontoon / Terminal / Berthing / Quay",
    colour: adsDarkBlue,
  },
  {
    id: "CU",
    lookup: "Commercial Utility",
    display: "Utility",
    colour: adsDarkBlue,
  },
  {
    id: "CU01",
    lookup: "Commercial Utility Electricity Sub Station",
    display: "Electricity Sub Station",
    colour: adsDarkBlue,
  },
  {
    id: "CU02",
    lookup: "Commercial Utility Landfill",
    display: "Landfill",
    colour: adsDarkBlue,
  },
  {
    id: "CU03",
    lookup: "Commercial Utility Power Station / Energy Production",
    display: "Power Station / Energy Production",
    colour: adsDarkBlue,
  },
  {
    id: "CU04",
    lookup: "Commercial Utility Pump House / Pumping Station / Water Tower",
    display: "Pump House / Pumping Station / Water Tower",
    colour: adsDarkBlue,
  },
  {
    id: "CU06",
    lookup: "Commercial Utility Telecommunication",
    display: "Telecommunication",
    colour: adsDarkBlue,
  },
  {
    id: "CU07",
    lookup: "Commercial Utility Water / Waste Water / Sewage Treatment Works",
    display: "Water / Waste Water / Sewage Treatment Works",
    colour: adsDarkBlue,
  },
  {
    id: "CU08",
    lookup: "Commercial Utility Gas / Oil Storage / Distribution",
    display: "Gas / Oil Storage / Distribution",
    colour: adsDarkBlue,
  },
  {
    id: "CU09",
    lookup: "Commercial Utility Other Utility Use",
    display: "Other Utility Use",
    colour: adsDarkBlue,
  },
  {
    id: "CU10",
    lookup: "Commercial Utility Waste Management",
    display: "Waste Management",
    colour: adsDarkBlue,
  },
  {
    id: "CU11",
    lookup: "Commercial Utility Telephone Box",
    display: "Telephone Box",
    colour: adsDarkBlue,
  },
  {
    id: "CU12",
    lookup: "Commercial Utility Dam",
    display: "Dam",
    colour: adsDarkBlue,
  },
  {
    id: "CX",
    lookup: "Commercial Emergency / Rescue Service",
    display: "Emergency / Rescue Service",
    colour: adsDarkBlue,
  },
  {
    id: "CX01",
    lookup:
      "Commercial Emergency / Rescue Service Police / Transport Police / Station",
    display: "Police / Transport Police / Station",
    colour: adsDarkBlue,
  },
  {
    id: "CX02",
    lookup: "Commercial Emergency / Rescue Service Fire Station",
    display: "Fire Station",
    colour: adsDarkBlue,
  },
  {
    id: "CX03",
    lookup: "Commercial Emergency / Rescue Service Ambulance Station",
    display: "Ambulance Station",
    colour: adsDarkBlue,
  },
  {
    id: "CX04",
    lookup: "Commercial Emergency / Rescue Service Lifeboat Services / Station",
    display: "Lifeboat Services / Station",
    colour: adsDarkBlue,
  },
  {
    id: "CX05",
    lookup:
      "Commercial Emergency / Rescue Service Coastguard Rescue / Lookout / Station",
    display: "Coastguard Rescue / Lookout / Station",
    colour: adsDarkBlue,
  },
  {
    id: "CX06",
    lookup: "Commercial Emergency / Rescue Service Mountain Rescue Station",
    display: "Mountain Rescue Station",
    colour: adsDarkBlue,
  },
  {
    id: "CX07",
    lookup: "Commercial Emergency / Rescue Service Lighthouse",
    display: "Lighthouse",
    colour: adsDarkBlue,
  },
  {
    id: "CX08",
    lookup: "Commercial Emergency / Rescue Service Police Box / Kiosk",
    display: "Police Box / Kiosk",
    colour: adsDarkBlue,
  },
  {
    id: "CZ",
    lookup: "Commercial Information",
    display: "Information",
    colour: adsDarkBlue,
  },
  {
    id: "CZ01",
    lookup: "Commercial Information Advertising Hoarding",
    display: "Advertising Hoarding",
    colour: adsDarkBlue,
  },
  {
    id: "CZ02",
    lookup: "Commercial Information Tourist Information Signage",
    display: "Tourist Information Signage",
    colour: adsDarkBlue,
  },
  {
    id: "CZ03",
    lookup: "Commercial Information Traffic Information Signage",
    display: "Traffic Information Signage",
    colour: adsDarkBlue,
  },
  {
    id: "L",
    lookup: "Land",
    display: "Land",
    colour: adsDarkBlue,
  },
  {
    id: "LA",
    lookup: "Land Agricultural",
    display: "Land Agricultural",
    colour: adsDarkBlue,
  },
  {
    id: "LA01",
    lookup: "Land Agricultural Grazing Land",
    display: "Grazing Land",
    colour: adsDarkBlue,
  },
  {
    id: "LA02",
    lookup: "Land Agricultural Permanent Crop / Crop Rotation",
    display: "Permanent Crop / Crop Rotation",
    colour: adsDarkBlue,
  },
  {
    id: "LB",
    lookup: "Land Ancillary Building",
    display: "Land Ancillary Building",
    colour: adsDarkBlue,
  },
  {
    id: "LC",
    lookup: "Land Burial Ground",
    display: "Burial Ground",
    colour: adsDarkBlue,
  },
  {
    id: "LC01",
    lookup: "Land Burial Ground Historic / Disused Cemetery / Graveyard",
    display: "Historic / Disused Cemetery / Graveyard",
    colour: adsDarkBlue,
  },
  {
    id: "LD",
    lookup: "Land Development",
    display: "Development",
    colour: adsDarkBlue,
  },
  {
    id: "LD01",
    lookup: "Land Development Development Site",
    display: "Development Site",
    colour: adsDarkBlue,
  },
  {
    id: "LF",
    lookup: "Land Forestry",
    display: "Forestry",
    colour: adsDarkBlue,
  },
  {
    id: "LF02",
    lookup: "Land Forestry Forest / Arboretum / Pinetum (Managed / Unmanaged)",
    display: "Forest / Arboretum / Pinetum (Managed / Unmanaged)",
    colour: adsDarkBlue,
  },
  {
    id: "LF03",
    lookup: "Land Forestry Woodland",
    display: "Woodland",
    colour: adsDarkBlue,
  },
  {
    id: "LL",
    lookup: "Land Allotment",
    display: "Allotment",
    colour: adsDarkBlue,
  },
  {
    id: "LM",
    lookup: "Land Amenity",
    display: "Amenity",
    colour: adsDarkBlue,
  },
  {
    id: "LM01",
    lookup: "Land Amenity Landscaped Roundabout",
    display: "Landscaped Roundabout",
    colour: adsDarkBlue,
  },
  {
    id: "LM02",
    lookup: "Land Amenity Verge / Central Reservation",
    display: "Verge / Central Reservation",
    colour: adsDarkBlue,
  },
  {
    id: "LM03",
    lookup: "Land Amenity Maintained Amenity Land",
    display: "Maintained Amenity Land",
    colour: adsDarkBlue,
  },
  {
    id: "LM04",
    lookup: "Land Amenity Maintained Surfaced Area",
    display: "Maintained Surfaced Area",
    colour: adsDarkBlue,
  },
  {
    id: "LO",
    lookup: "Land Open Space",
    display: "Open Space",
    colour: adsDarkBlue,
  },
  {
    id: "LO01",
    lookup: "Land Open Space Heath / Moorland",
    display: "Heath / Moorland",
    colour: adsDarkBlue,
  },
  {
    id: "LP",
    lookup: "Land Park",
    display: "Park",
    colour: adsDarkBlue,
  },
  {
    id: "LP01",
    lookup: "Land Park Public Park / Garden",
    display: "Public Park / Garden",
    colour: adsDarkBlue,
  },
  {
    id: "LP02",
    lookup: "Land Park Public Open Space / Nature Reserve",
    display: "Public Open Space / Nature Reserve",
    colour: adsDarkBlue,
  },
  {
    id: "LP03",
    lookup: "Land Park Playground",
    display: "Playground",
    colour: adsDarkBlue,
  },
  {
    id: "LP04",
    lookup: "Land Park Private Park / Garden",
    display: "Private Park / Garden",
    colour: adsDarkBlue,
  },
  {
    id: "LU",
    lookup: "Land Unused Land",
    display: "Unused Land",
    colour: adsDarkBlue,
  },
  {
    id: "LU01",
    lookup: "Land Unused Land Vacant / Derelict Land",
    display: "Vacant / Derelict Land",
    colour: adsDarkBlue,
  },
  {
    id: "LW",
    lookup: "Land Water",
    display: "Water",
    colour: adsDarkBlue,
  },
  {
    id: "LW01",
    lookup: "Land Water Lake / Reservoir",
    display: "Lake / Reservoir",
    colour: adsDarkBlue,
  },
  {
    id: "LW02",
    lookup: "Land Water Named Pond",
    display: "Named Pond",
    colour: adsDarkBlue,
  },
  {
    id: "LW03",
    lookup: "Land Water Waterway",
    display: "Waterway",
    colour: adsDarkBlue,
  },
  {
    id: "M",
    lookup: "Military",
    display: "Military",
    colour: adsDarkBlue,
  },
  {
    id: "MA",
    lookup: "Military Army",
    display: "Army",
    colour: adsDarkBlue,
  },
  {
    id: "MB",
    lookup: "Military Ancillary Building",
    display: "Military Ancillary Building",
    colour: adsDarkBlue,
  },
  {
    id: "MF",
    lookup: "Military Air Force",
    display: "Air Force",
    colour: adsDarkBlue,
  },
  {
    id: "MG",
    lookup: "Military Defence Estates",
    display: "Defence Estates",
    colour: adsDarkBlue,
  },
  {
    id: "MN",
    lookup: "Military Navy",
    display: "Navy",
    colour: adsDarkBlue,
  },
  {
    id: "P",
    lookup: "Parent Shell",
    display: "Parent Shell",
    colour: adsDarkBlue,
  },
  {
    id: "PP",
    lookup: "Parent Shell Property Shell",
    display: "Property Shell",
    colour: adsDarkBlue,
  },
  {
    id: "PS",
    lookup: "Parent Shell Street Record",
    display: "Street Record",
    colour: adsDarkBlue,
  },
  {
    id: "R",
    lookup: "Residential",
    display: "Residential",
    colour: adsDarkBlue,
  },
  {
    id: "RB",
    lookup: "Residential Ancillary Building",
    display: "Residential Ancillary Building",
    colour: adsDarkBlue,
  },
  {
    id: "RC",
    lookup: "Residential Car Park Space",
    display: "Car Park Space",
    colour: adsDarkBlue,
  },
  {
    id: "RC01",
    lookup: "Residential Car Park Space Allocated Parking",
    display: "Allocated Parking",
    colour: adsDarkBlue,
  },
  {
    id: "RD",
    lookup: "Residential Dwelling",
    display: "Dwelling",
    colour: adsDarkBlue,
  },
  {
    id: "RD01",
    lookup: "Residential Dwelling Caravan",
    display: "Caravan",
    colour: adsDarkBlue,
  },
  {
    id: "RD02",
    lookup: "Residential Dwelling Detached",
    display: "Detached",
    colour: adsDarkBlue,
  },
  {
    id: "RD03",
    lookup: "Residential Dwelling Semi-detached",
    display: "Semi-detached",
    colour: adsDarkBlue,
  },
  {
    id: "RD04",
    lookup: "Residential Dwelling Terraced",
    display: "Terraced",
    colour: adsDarkBlue,
  },
  {
    id: "RD06",
    lookup:
      "Residential Dwelling Self-Contained Flat (Includes Maisonette / Apartment)",
    display: "Self-Contained Flat (Includes Maisonette / Apartment)",
    colour: adsDarkBlue,
  },
  {
    id: "RD07",
    lookup: "Residential Dwelling House Boat",
    display: "House Boat",
    colour: adsDarkBlue,
  },
  {
    id: "RD08",
    lookup: "Residential Dwelling Sheltered Accommodation",
    display: "Sheltered Accommodation",
    colour: adsDarkBlue,
  },
  {
    id: "RD10",
    lookup: "Residential Dwelling Privately Owned Holiday Caravan / Chalet",
    display: "Privately Owned Holiday Caravan / Chalet",
    colour: adsDarkBlue,
  },
  {
    id: "RG",
    lookup: "Residential Garage",
    display: "Garage",
    colour: adsDarkBlue,
  },
  {
    id: "RG02",
    lookup: "Residential Garage Lock-up garage / garage court",
    display: "Lock-up garage / garage court",
    colour: adsDarkBlue,
  },
  {
    id: "RH",
    lookup: "Residential House In Multiple Occupation",
    display: "House In Multiple Occupation",
    colour: adsDarkBlue,
  },
  {
    id: "RH01",
    lookup: "Residential House In Multiple Occupation HMO Parent",
    display: "HMO Parent",
    colour: adsDarkBlue,
  },
  {
    id: "RH02",
    lookup:
      "Residential House In Multiple Occupation HMO Bedsit / Other Non Self Contained Accommodation",
    display: "HMO Bedsit / Other Non Self Contained Accommodation",
    colour: adsDarkBlue,
  },
  {
    id: "RH03",
    lookup: "Residential House In Multiple Occupation HMO not further divided",
    display: "HMO not further divided",
    colour: adsDarkBlue,
  },
  {
    id: "RI",
    lookup: "Residential Residential Institution",
    display: "Residential Institution",
    colour: adsDarkBlue,
  },
  {
    id: "RI01",
    lookup: "Residential Residential Institution Care / Nursing Home",
    display: "Care / Nursing Home",
    colour: adsDarkBlue,
  },
  {
    id: "RI02",
    lookup: "Residential Residential Institution Communal Residence",
    display: "Communal Residence",
    colour: adsDarkBlue,
  },
  {
    id: "RI03",
    lookup: "Residential Residential Institution Residential Education",
    display: "Residential Education",
    colour: adsDarkBlue,
  },
  {
    id: "U",
    lookup: "Unclassified",
    display: "Unclassified",
    colour: adsDarkBlue,
  },
  {
    id: "UC",
    lookup: "Unclassified Awaiting Classification",
    display: "Awaiting Classification",
    colour: adsDarkBlue,
  },
  {
    id: "UP",
    lookup: "Unclassified Pending Internal Investigation",
    display: "Pending Internal Investigation",
    colour: adsDarkBlue,
  },
  {
    id: "X",
    lookup: "Dual Use",
    display: "Dual Use",
    colour: adsDarkBlue,
  },
  {
    id: "Z",
    lookup: "Object of Interest",
    display: "Object of Interest",
    colour: adsDarkBlue,
  },
  {
    id: "ZA",
    lookup: "Object of Interest Archaeological Dig Site",
    display: "Archaeological Dig Site",
    colour: adsDarkBlue,
  },
  {
    id: "ZM",
    lookup: "Object of Interest Monument",
    display: "Monument",
    colour: adsDarkBlue,
  },
  {
    id: "ZM01",
    lookup: "Object of Interest Monument Obelisk / Milestone / Standing Stone",
    display: "Obelisk / Milestone / Standing Stone",
    colour: adsDarkBlue,
  },
  {
    id: "ZM02",
    lookup: "Object of Interest Monument Memorial / Market Cross",
    display: "Memorial / Market Cross",
    colour: adsDarkBlue,
  },
  {
    id: "ZM03",
    lookup: "Object of Interest Monument Statue",
    display: "Statue",
    colour: adsDarkBlue,
  },
  {
    id: "ZM04",
    lookup: "Object of Interest Monument Castle / Historic Ruin",
    display: "Castle / Historic Ruin",
    colour: adsDarkBlue,
  },
  {
    id: "ZM05",
    lookup: "Object of Interest Monument Other Structure",
    display: "Other Structure",
    colour: adsDarkBlue,
  },
  {
    id: "ZS",
    lookup: "Object of Interest Stately Home",
    display: "Stately Home",
    colour: adsDarkBlue,
  },
  {
    id: "ZU",
    lookup: "Object of Interest Underground Feature",
    display: "Underground Feature",
    colour: adsDarkBlue,
  },
  {
    id: "ZU01",
    lookup: "Object of Interest Underground Feature Cave",
    display: "Cave",
    colour: adsDarkBlue,
  },
  {
    id: "ZU04",
    lookup: "Object of Interest Underground Feature Pothole / Natural Hole",
    display: "Pothole / Natural Hole",
    colour: adsDarkBlue,
  },
  {
    id: "ZV",
    lookup: "Object of Interest Other Underground Feature",
    display: "Other Underground Feature",
    colour: adsDarkBlue,
  },
  {
    id: "ZV01",
    lookup: "Object of Interest Other Underground Feature Cellar",
    display: "Cellar",
    colour: adsDarkBlue,
  },
  {
    id: "ZV02",
    lookup: "Object of Interest Other Underground Feature Disused Mine",
    display: "Disused Mine",
    colour: adsDarkBlue,
  },
  {
    id: "ZV03",
    lookup: "Object of Interest Other Underground Feature Well / Spring",
    display: "Well / Spring",
    colour: adsDarkBlue,
  },
  {
    id: "ZW",
    lookup: "Object of Interest Places Of Worship",
    display: "Places Of Worship",
    colour: adsDarkBlue,
  },
];

export default BLPUClassification;
