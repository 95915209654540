const ESUTolerance = [
  {
    id: 1,
    gpText: "1m",
  },
  {
    id: 5,
    gpText: "5m",
  },
  {
    id: 10,
    gpText: "10m",
  },
  {
    id: 60,
    gpText: "50m",
  },
];

export default ESUTolerance;
