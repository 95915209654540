import { adsDarkPurple } from "../utils/ADSColours";

const ReinstatementType = [
  {
    id: 0,
    gpText: undefined,
    osText: "Carriageway special type (30msa - 125msa)",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 1,
    gpText: "Carriageway type 1 (10 to 30 MSA)",
    osText: "Carriageway type 1 (10 - 30msa)",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 2,
    gpText: "Carriageway type 2 (2.5 to 10 MSA)",
    osText: "Carriageway type 2 (2.5 - 10msa)",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 3,
    gpText: "Carriageway type 3 (0.5 to 2.5 MSA)",
    osText: "Carriageway type 3 (0.5 - 2.5msa)",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 4,
    gpText: "Carriageway type 4 (up to 0.5 MSA)",
    osText: "Carriageway type 4 (<0.5msa)",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 5,
    gpText: "Carriageway type 0 (30 to 125 MSA)",
    osText: "High duty footway, footpath or cycle track",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 6,
    gpText: "High Duty Footway",
    osText: "High amenity footway, footpath or cycle track",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 7,
    gpText: "High Amenity Footway",
    osText: "Other footway, footpath or cycle track",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 8,
    gpText: "Other Footways",
    osText: undefined,
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 9,
    gpText: "Private Street",
    osText: undefined,
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 10,
    gpText: "Carriageway type 6 (over 125 MSA)",
    osText: "Carriageway friction coatings",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 11,
    gpText: "Street maintained by another Highway Authority",
    osText: "Carriageway coloured surfacings",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 12,
    gpText: "Street outside scope of EToN",
    osText: "Carriageway porous asphalts",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 13,
    gpText: undefined,
    osText: "High amenity carriageway",
    colour: adsDarkPurple,
    default: false,
  },
  {
    id: 14,
    gpText: undefined,
    osText: "All private streets",
    colour: adsDarkPurple,
    default: false,
  },
];

export default ReinstatementType;
