const OneWayExemptionType = [
  {
    id: 1,
    gpText: "Buses",
  },
  {
    id: 2,
    gpText: "Cycles",
  },
  {
    id: 3,
    gpText: "Taxis",
  },
  {
    id: 4,
    gpText: "Emergency vehicles",
  },
  {
    id: 5,
    gpText: "HGVs and Vans",
  },
];

export default OneWayExemptionType;