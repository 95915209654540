import { adsLightGreyA } from "../utils/ADSColours";

const SwaOrgRef = [
  {
    id: 10,
    gpText: "Cadent Gas Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 11,
    gpText: "National Highways",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: true,
  },
  {
    id: 12,
    gpText: "South Wales Trunk Road Agency",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 13,
    gpText: "Mid Wales Trunk Road Agency",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 14,
    gpText: "North Wales Trunk Road Agency",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 16,
    gpText: "Welsh Government",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: true,
  },
  {
    id: 17,
    gpText: "BAA International Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 18,
    gpText: "M6 Toll Motorway (MEL)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 19,
    gpText: undefined,
    osText: "Scottish Executive",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 20,
    gpText: "Transport for London (TFL)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: true,
  },
  {
    id: 30,
    gpText: "British Telecommunications Public Limited Company",
    osText: "British Telecom",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 50,
    gpText: "Mercury Communications Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 60,
    gpText: "In Focus Public Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 70,
    gpText: "Vodafone Enterprise Europe (UK) Limited",
    osText: "Cable and Wireless",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 114,
    gpText: "Bath and North East Somerset Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 116,
    gpText: "Bristol City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 119,
    gpText: "South Gloucestershire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 121,
    gpText: "North Somerset Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 230,
    gpText: "Luton Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 235,
    gpText: "Bedford Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 240,
    gpText: "Central Bedfordshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 335,
    gpText: "Bracknell Forest Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 340,
    gpText: "West Berkshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 345,
    gpText: "Reading Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 350,
    gpText: "Slough Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 355,
    gpText: "Royal Borough of Windsor and Maidenhead",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 360,
    gpText: "Wokingham Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 430,
    gpText: "Buckinghamshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 435,
    gpText: "Milton Keynes City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 440,
    gpText: "Buckinghamshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 535,
    gpText: "Cambridgeshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 540,
    gpText: "Peterborough City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 650,
    gpText: "Halton Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 655,
    gpText: "Warrington Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 660,
    gpText: "Cheshire East Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 665,
    gpText: "Cheshire West and Chester Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 724,
    gpText: "Hartlepool Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 728,
    gpText: "Redcar and Cleveland Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 734,
    gpText: "Middlesbrough Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 738,
    gpText: "Stockton-on-Tees Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 835,
    gpText: "Council of the Isles of Scilly",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 840,
    gpText: "Cornwall Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 935,
    gpText: "Westmorland and Furness Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 940,
    gpText: "Cumberland Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1050,
    gpText: "Derbyshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1055,
    gpText: "Derby City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1155,
    gpText: "Devon County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1160,
    gpText: "Plymouth City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1165,
    gpText: "Torbay Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1260,
    gpText: "Bournemouth Christchurch and Poole Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1265,
    gpText: "Dorset Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1350,
    gpText: "Darlington Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1355,
    gpText: "Durham County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1440,
    gpText: "East Sussex County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1445,
    gpText: "Brighton & Hove City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1585,
    gpText: "Essex County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1590,
    gpText: "Southend-on-Sea City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1595,
    gpText: "Thurrock Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1600,
    gpText: "Gloucestershire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1770,
    gpText: "Hampshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1775,
    gpText: "Portsmouth City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1780,
    gpText: "Southampton City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1850,
    gpText: "Herefordshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1855,
    gpText: "Worcestershire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 1900,
    gpText: "Hertfordshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2001,
    gpText: "East Riding of Yorkshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2002,
    gpText: "North East Lincolnshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2003,
    gpText: "North Lincolnshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2004,
    gpText: "Hull City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2114,
    gpText: "Isle of Wight Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2275,
    gpText: "Kent County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2280,
    gpText: "Medway Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2371,
    gpText: "Lancashire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2372,
    gpText: "Blackburn with Darwen Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2373,
    gpText: "Blackpool Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2460,
    gpText: "Leicestershire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2465,
    gpText: "Leicester City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2470,
    gpText: "Rutland County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2500,
    gpText: "Lincolnshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2600,
    gpText: "Norfolk County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2741,
    gpText: "City of York Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2745,
    gpText: "North Yorkshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2840,
    gpText: "North Northamptonshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2845,
    gpText: "West Northamptonshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 2935,
    gpText: "Northumberland County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3055,
    gpText: "Nottinghamshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3060,
    gpText: "Nottingham City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3061,
    gpText: "Greater Nottingham Light Rapid Transit",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3100,
    gpText: "Oxfordshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3240,
    gpText: "Telford & Wrekin Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3245,
    gpText: "Shropshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3300,
    gpText: "Somerset Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3450,
    gpText: "Staffordshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3455,
    gpText: "Stoke-on-Trent City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3500,
    gpText: "Suffolk County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3600,
    gpText: "Surrey County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3700,
    gpText: "Warwickshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3800,
    gpText: "West Sussex County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3935,
    gpText: "Swindon Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 3940,
    gpText: "Wiltshire Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4205,
    gpText: "Bolton Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4210,
    gpText: "Bury Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4215,
    gpText: "Manchester City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4220,
    gpText: "Oldham Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4225,
    gpText: "Rochdale Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4230,
    gpText: "Salford City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4235,
    gpText: "Stockport Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4240,
    gpText: "Tameside Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4245,
    gpText: "Trafford Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4250,
    gpText: "Wigan Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4305,
    gpText: "Knowsley Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4310,
    gpText: "Liverpool City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4315,
    gpText: "St. Helens Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4320,
    gpText: "Sefton Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4325,
    gpText: "Wirral Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4405,
    gpText: "Barnsley Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4410,
    gpText: "City of Doncaster Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4415,
    gpText: "Rotherham Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4420,
    gpText: "Sheffield City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4505,
    gpText: "Gateshead Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4510,
    gpText: "Newcastle City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4515,
    gpText: "North Tyneside Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4520,
    gpText: "South Tyneside Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4525,
    gpText: "Sunderland City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4605,
    gpText: "Birmingham City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4610,
    gpText: "Coventry City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4615,
    gpText: "Dudley Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4620,
    gpText: "Sandwell Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4625,
    gpText: "Solihull Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4630,
    gpText: "Walsall Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4635,
    gpText: "City of Wolverhampton Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4705,
    gpText: "City of Bradford Metropolitan District Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4710,
    gpText: "Calderdale Metropolitan Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4715,
    gpText: "Kirklees Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4720,
    gpText: "Leeds City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4725,
    gpText: "City of Wakefield Metropolitan District Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 4900,
    gpText: "Defence Estates",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5030,
    gpText: "City of London Corporation",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5060,
    gpText: "London Borough of Barking and Dagenham",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5090,
    gpText: "London Borough of Barnet",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5120,
    gpText: "London Borough of Bexley",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5150,
    gpText: "London Borough of Brent",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5180,
    gpText: "London Borough of Bromley",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5210,
    gpText: "London Borough of Camden",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5240,
    gpText: "London Borough of Croydon",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5270,
    gpText: "London Borough of Ealing",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5300,
    gpText: "London Borough of Enfield",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5330,
    gpText: "Royal Borough of Greenwich",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5360,
    gpText: "London Borough of Hackney",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5390,
    gpText: "London Borough of Hammersmith and Fulham",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5420,
    gpText: "London Borough of Haringey",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5450,
    gpText: "London Borough of Harrow",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5480,
    gpText: "London Borough of Havering",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5510,
    gpText: "London Borough of Hillingdon",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5540,
    gpText: "London Borough of Hounslow",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5570,
    gpText: "London Borough of Islington",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5600,
    gpText: "Royal Borough of Kensington and Chelsea",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5630,
    gpText: "Royal Borough of Kingston upon Thames",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5660,
    gpText: "London Borough of Lambeth",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5690,
    gpText: "London Borough of Lewisham",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5720,
    gpText: "London Borough of Merton",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5750,
    gpText: "London Borough of Newham",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5780,
    gpText: "London Borough of Redbridge",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5810,
    gpText: "London Borough of Richmond upon Thames",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5840,
    gpText: "London Borough of Southwark",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5870,
    gpText: "London Borough of Sutton",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5900,
    gpText: "London Borough of Tower Hamlets",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5930,
    gpText: "London Borough of Waltham Forest",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5960,
    gpText: "London Borough of Wandsworth",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 5990,
    gpText: "City of Westminster",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6805,
    gpText: "Isle of Anglesey County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6810,
    gpText: "Gwynedd Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6815,
    gpText: "City of Cardiff Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6820,
    gpText: "Ceredigion County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6825,
    gpText: "Carmarthenshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6830,
    gpText: "Denbighshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6835,
    gpText: "Flintshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6840,
    gpText: "Monmouthshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6845,
    gpText: "Pembrokeshire County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6850,
    gpText: "Powys County Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6855,
    gpText: "City and County of Swansea Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6905,
    gpText: "Conwy County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6910,
    gpText: "Blaenau Gwent County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6915,
    gpText: "Bridgend County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6920,
    gpText: "Caerphilly County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6925,
    gpText: "Merthyr Tydfil County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6930,
    gpText: "Neath Port Talbot County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6935,
    gpText: "Newport City Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6940,
    gpText: "Rhondda Cynon Taff County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6945,
    gpText: "Torfaen County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6950,
    gpText: "Vale of Glamorgan Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6955,
    gpText: "Wrexham County Borough Council",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6980,
    gpText: undefined,
    osText: "Scottish Government NE (Bear)",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6981,
    gpText: undefined,
    osText: "Scottish Government NW (Transerv)",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6982,
    gpText: undefined,
    osText: "Scottish Government SW (Amey)",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 6983,
    gpText: undefined,
    osText: "Scottish Government SE",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7001,
    gpText: "Northern Powergrid (Yorkshire) plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7002,
    gpText: "Scottish Electric Power Distribution plc",
    osText: "Scottish and Southern Energy",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7003,
    gpText: "National Grid Electricity Distribution (South West)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7004,
    gpText: "South Eastern Power Networks plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7005,
    gpText: "Electricity North West Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7006,
    gpText: "Northern Powergrid (NorthEast) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7007,
    gpText: "National Grid Electricity Distribution (West Midlands)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7008,
    gpText: "SP MANWEB plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7009,
    gpText: "EDF Energy Customers plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7010,
    gpText: "Eastern Power Networks plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7011,
    gpText: "National Grid Electricity Distribution (East Midlands)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7012,
    gpText: "National Grid Electricity Distribution (South Wales)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7015,
    gpText: "National Grid Electricity Transmission plc",
    osText: "National Grid",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7016,
    gpText: "GDF Suez Teeside Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7019,
    gpText: "Scottish Power Energy Retail Limited",
    osText: "Scottish Power",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7025,
    gpText: "Andover Cable Services",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7026,
    gpText: "Anglia Cable Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7027,
    gpText: "Yorkshire Cable Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7028,
    gpText: "Birmingham Cable Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7029,
    gpText: "NTL (Broadland) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7030,
    gpText: "Cable London Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7031,
    gpText: "Telewest Communications (Midlands) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7032,
    gpText: "Cabletel Bedfordshire",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7033,
    gpText: "Cablevision (Wellingborough) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7034,
    gpText: "NTL Cambridge Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7035,
    gpText: "Telewest Communications (Tyneside) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7036,
    gpText: "County Cable Comms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7037,
    gpText: "Caledonian Communications Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7039,
    gpText: "Eurobell (South West) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7040,
    gpText: "Diamond Cable Communications Limited (Formerly Diamond Cable Grantham)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7041,
    gpText: "Yorkshire Cable Communications Limited (Formerly Yorkshire Cable Comms Doncaster)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7042,
    gpText: "East Coast Cable Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7043,
    gpText: "BCM South",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7044,
    gpText: "Eurobell (Sussex) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7045,
    gpText: "BCM North and East",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7047,
    gpText: "NTL Kirklees",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7048,
    gpText: "LCL Cable Comms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7049,
    gpText: "Middlesex Cable Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7050,
    gpText: "Cabletel South Wales (Newport)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7051,
    gpText: "North Downs Cable",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7052,
    gpText: "Cable North West (Merseyside)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7053,
    gpText: "NTL (Norwich) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7055,
    gpText: "Oxford Cable",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7056,
    gpText: "NTL (Peterborough) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7057,
    gpText: "Yorkshire Cable Communications Limited (Formerly Yorkshire Cable Comms Sheffield)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7058,
    gpText: "NTL South Central Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7059,
    gpText: "Starvision Network",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7060,
    gpText: "Swindon Cable",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7061,
    gpText: "Telewest Communications (South West) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7062,
    gpText: "Videotron Corporation (Ealing)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7063,
    gpText: "Yorkshire Cable Communications Limited (Formerly Yorkshire Cable Comms Wakefield)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7064,
    gpText: "Wessex Cable",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7065,
    gpText: "Westminster Cable Company",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7066,
    gpText: "Cable Corporation Limited (The) (Formerly The Cable Corporation Windsor)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7067,
    gpText: "NTL (Leeds) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7068,
    gpText: "Yorkshire Cable Communications Limited (Formerly Yorkshire Cable Comms Bradford)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7070,
    gpText: "Shell & BP Services Limited",
    osText: "Shell",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7072,
    gpText: "London Underground Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7073,
    gpText: "KCom Group Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7074,
    gpText: "Ionica Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7075,
    gpText: "Colt Technology Services",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7076,
    gpText: "Vodafone Limited",
    osText: "Vodafone Limited",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7077,
    gpText: "Corange Personal Communications Services Limited (Formerly Hutchinson Microtel)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7078,
    gpText: "T-Mobile No.5 Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7079,
    gpText: "Scottish Hydro-Electric (Comms)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7080,
    gpText: "Energis Communications Limited",
    osText: "Energis",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7081,
    gpText: "Verizon UK Limited (Formerly Worldcom International Ltd)",
    osText: "Verizon",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7082,
    gpText: "KCom Group Limited (National)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7083,
    gpText: "Your Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7084,
    gpText: "Global One Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7085,
    gpText: "Telstra Global Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7086,
    gpText: "Verizon UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7087,
    gpText: "Transport for London Street Management",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7089,
    gpText: "British Pipeline Agency Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7090,
    gpText: "Mainline Pipelines Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7091,
    gpText: "Esso Exploration and Production UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7092,
    gpText: "The Oil & Pipelines Agency",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7093,
    gpText: "Network Rail",
    osText: "Network Rail",
    colour: adsLightGreyA,
    maintainingAuthority: true,
  },
  {
    id: 7094,
    gpText: "Lumen Technologies Inc. (Formerly Centurylink Communications UK Limited)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7095,
    gpText: "NTL (Wearside) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7096,
    gpText: "NTL (B) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7097,
    gpText: "Telewest Communications (Fylde & Wyre) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7098,
    gpText: "Cable Corporation Limited (The)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7099,
    gpText: "Cable London Limited (Formerly Cable London plc Enfield)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7100,
    gpText: "Cable London Limited (Formerly Cable London plc Hackney)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7101,
    gpText: "Cable London Limited (Formerly Cable London plc Haringey)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7102,
    gpText: "Cable North West (North Liverpool)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7103,
    gpText: "Cable North West (Central Lancashire)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7104,
    gpText: "Cable North West (St Helens)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7105,
    gpText: "Cable North West (Wigan)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7106,
    gpText: "Cabletel Herts and Beds Limited (Formerly Cabletel Central Hertfordshire)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7107,
    gpText: "Cabletel Herts and Beds Limited (Formerly Cabletel Hertfordshire)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7108,
    gpText: "Cabletel Herts and Beds Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7109,
    gpText: "Virgin Media PCHC II Limited (Formerly Cabletel South Wales Cardiff)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7110,
    gpText: "Virgin Media PCHC II Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7111,
    gpText: "Cabletel Surrey and Hampshire Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7112,
    gpText: "Cable on Demand Limited (Formerly Comcast Teesside Darlington)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7113,
    gpText: "Cable on Demand Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7114,
    gpText: "Diamond Cable Communications Limited (Formerly Diamond Cable Darlington)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7115,
    gpText: "Diamond Cable Communications Limited (Formerly Diamond Cable Lincoln)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7116,
    gpText: "Diamond Cable (Mansfield)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7117,
    gpText: "Diamond Cable Communications Limited (Formerly Diamond Cable Melton Mowbray)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7118,
    gpText: "Diamond Cable Communications Limited (Formerly Diamond Cable Newark)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7119,
    gpText: "NTL Midlands Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7120,
    gpText: "NTL (South East) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7121,
    gpText: "NTL (South East) Limited (Formerly Encom Cable TV and Comms Epping Forest)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7122,
    gpText: "NTL (South East) Limited (Formerly Encom Cable TV and Comms Greater London)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7123,
    gpText: "NTL (South East) Limited (Formerly Encom Cable TV and Comms Havering)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7124,
    gpText: "NTL (South East) Limited (Formerly Encom Cable TV and Comms Newham)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7125,
    gpText: "NTL (South East) Limited (Formerly Encom Cable TV and Comms Waltham Forest)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7126,
    gpText: "Eurobell (West Kent) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7127,
    gpText: "NTL Holdings (Fenland) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7128,
    gpText: "NTL (Leeds) Limited (Formerly Jones Cable Group Harrogate)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7129,
    gpText: "NTL (Leeds) Limited (Formerly Jones Cable Group of Leeds)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7130,
    gpText: "NTL (South Hertfordshire) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7142,
    gpText: "NTL CableComms Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7145,
    gpText: "National Grid Telecoms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7146,
    gpText: "Telecential Communications (Northants) Limited Partnership",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7147,
    gpText: "Telecential Communications (Northants) Limited Partnership (Formerly Telecential Comms Northampton)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7148,
    gpText: "Telecential Communications Limited Partnership",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7149,
    gpText: "Telecential Communications (Warwickshire) Limited Partnership",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7150,
    gpText: "Telecential Communications Limited Partnership (Formerly Telecential Comms Rugby)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7151,
    gpText: "Telecential Communications Limited Partnership (Formerly Telecential Comms Stratford)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7152,
    gpText: "Telecential Communications (Herts) Limited Partnership",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7153,
    gpText: "Telford Telecomms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7154,
    gpText: "Telewest Communications (Cotswolds) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7155,
    gpText: "Telewest Communications (London South) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7156,
    gpText: "Telewest Communications (South Thames Estuary) Limited (Formerly United Artists Comms Kingston)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7157,
    gpText: "Telewest Communications (South Thames Estuary) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7158,
    gpText: "Telewest Communications (North East) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7159,
    gpText: "Telewest Communications (South East) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7160,
    gpText: "Virgin Media Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7161,
    gpText: "NTL (Greenwich and Lewisham) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7162,
    gpText: "Videotron Corporation (Hammersmith)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7163,
    gpText: "NTL (Harrow) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7164,
    gpText: "Videotron Corporation (Kensington)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7165,
    gpText: "NTL (Lamberth and Southwark) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7166,
    gpText: "NTL (Southampton and Eastleigh) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7167,
    gpText: "NTL (Thamesmead) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7168,
    gpText: "NTL (Wandsworth) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7169,
    gpText: "Videotron Corporation (Winchester)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7170,
    gpText: "Yorkshire Cable Communications Limited (Formerly Yorkshire Cable Comms Halifax)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7171,
    gpText: "BT New Towns System",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7172,
    gpText: "Broadcast Satellite Television",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7173,
    gpText: "Cablecom Investments Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7174,
    gpText: "John Jones Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7175,
    gpText: "John Sulwyn Evans",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7176,
    gpText: "Metro Digital Television Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7177,
    gpText: "Multichannel Television",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7179,
    gpText: "Salford Cable Television",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7180,
    gpText: "Sapphire Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7181,
    gpText: "Tawd Valley Cable Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7182,
    gpText: "Telefonica UK Limited",
    osText: "O2 UK Ltd",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7183,
    gpText: "Lumen Technologies Inc.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7184,
    gpText: "Rugby Cement",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7185,
    gpText: "ICI Pipelines",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7186,
    gpText: "British Oxygen Gases Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7187,
    gpText: "British Waterways Board",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7188,
    gpText: "DfT Road Statistics Division",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7189,
    gpText: "Diamond Cable Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7190,
    gpText: "Tamworth Cable Comms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7191,
    gpText: "Lichfield Cable Comms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7192,
    gpText: "Burton-upon-Trent Cable Comms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7193,
    gpText: "Hincley Cable Comms",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7194,
    gpText: "Southern East Anglia Cable",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7198,
    gpText: "Birmingham Cable Limited (Formerly Birmingham Cable Wythall)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7203,
    gpText: "Diamond Cable (Lincolnshire)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7205,
    gpText: "Videotron Southampton (Totton and Hythe)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7206,
    gpText: "Fina Pipelines",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7207,
    gpText: "Government Office (London)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7208,
    gpText: "Broadband Ventures Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7209,
    gpText: "Vital Energi Utilities Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7210,
    gpText: "London Transport Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7211,
    gpText: "Slough Heat and Power",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7212,
    gpText: "Traffic Director for London",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7213,
    gpText: "Port of London Authority",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7214,
    gpText: "Royal Parks",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7215,
    gpText: "Transport for Greater Manchester",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7216,
    gpText: "London Dockland Development Corp",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7217,
    gpText: "Arqiva Limited (Formerly National Transcommunications Ltd.)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7218,
    gpText: "Independent Pipelines Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7219,
    gpText: "NTL Telecom Service Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7220,
    gpText: "Environment Agency",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7221,
    gpText: "RM Property and Facilities Solutions Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7222,
    gpText: "Nottingham City Heating",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7223,
    gpText: "Fibernet UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7224,
    gpText: "Thus Limited",
    osText: "Thus",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7225,
    gpText: "Sky Telecommunications Services Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7226,
    gpText: "NWP Street Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7227,
    gpText: "KPN Eurorings B.V. (Formerly KPN Telecom UK Ltd)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7228,
    gpText: "Global Telecommunications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7229,
    gpText: "GC Pan European Crossing UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7230,
    gpText: "Arelion UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7231,
    gpText: "GTC Pipelines Limited",
    osText: "GTC Pipelines Limited",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7232,
    gpText: "Level 3 Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7233,
    gpText: "Orange Personal Communications Services Limited",
    osText: "Orange PCS",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7234,
    gpText: "Flute Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7235,
    gpText: "Zayo Group UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7236,
    gpText: "Zayo Infrastructure (UK) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7237,
    gpText: "Telewest Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7239,
    gpText: "Windsor Television Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7240,
    gpText: "Virgin Media Wholesale Limited",
    osText: "Telewest",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7241,
    gpText: "Gamma Telecom Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7242,
    gpText: "ESP Connections Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7243,
    gpText: "Eircom (UK) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7244,
    gpText: "Neos Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7245,
    gpText: "Interoute Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7246,
    gpText: "360-Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7247,
    gpText: "Redcentric Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7248,
    gpText: "Tata Communications (UK) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7249,
    gpText: "186K Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7250,
    gpText: "EE Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7251,
    gpText: "Wightfibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7252,
    gpText: "Oncue Telecommunications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7253,
    gpText: "Carrier 1 UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7254,
    gpText: "Interoute Vtesse Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7255,
    gpText: "ESP Networks Limited",
    osText: "United Utilities",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7256,
    gpText: "ESP Pipelines Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7257,
    gpText: "Mowlem Energy Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7258,
    gpText: "Omne Telecommunications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7259,
    gpText: "Geo Metro Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7260,
    gpText: "E S Pipelines Ltd",
    osText: "E S Pipelines Limited",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7261,
    gpText: "Citylink Telecommunications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7262,
    gpText: "CH2M (formerly Halcrow)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7263,
    gpText: "51Deg Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7264,
    gpText: "Hutchison 3G UK Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7265,
    gpText: "Utility Grid Installations Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7266,
    gpText: "Fibrespan Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7267,
    gpText: "KPN Eurorings BV",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7268,
    gpText: "ESP Electricity Limited (Formerly Laing Energy Ltd)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7269,
    gpText: "Last Mile Electricity Limited (Formerly Global Utility Connections)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7270,
    gpText: "Southern Gas Networks plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7271,
    gpText: "Northern Gas Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7272,
    gpText: "Wales & West Utilities Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7273,
    gpText: "Scotland Gas Networks plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7274,
    gpText: "NTL National Networks Limited",
    osText: "NTL:",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7275,
    gpText: "Timico Partner Services Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7276,
    gpText: "VTL (UK) Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7277,
    gpText: "Lancaster University Network Services Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7278,
    gpText: "MLL Telecom Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7279,
    gpText: "Cityspace Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7281,
    gpText: "Independent Power Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7283,
    gpText: "M6 Concessionaire",
    osText: "Scottish Government M6",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7284,
    gpText: "M77 Concessionaire",
    osText: "Scottish Government M77",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7285,
    gpText: "Historic Scotland",
    osText: "Historic Environment Scotland",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7286,
    gpText: "Scottish National Heritage",
    osText: "Scottish Natural Heritage",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7287,
    gpText: "Scottish Wildlife Trust",
    osText: "Scottish Wildlife Trust",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7294,
    gpText: "Fulcrum Pipelines Limited",
    osText: "Fulcrum",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7297,
    gpText: "Airwave Solutions Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7299,
    gpText: "TalkTalk Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7304,
    gpText: "Geo Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7305,
    gpText: "Fibrespeed Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7307,
    gpText: "EUNetworks Group Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7308,
    gpText: "Key Traffic Systems Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7309,
    gpText: "ESP Electricity Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7310,
    gpText: "Welsh Highland Railway Construction Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7311,
    gpText: "Last Mile Gas Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7312,
    gpText: "H20 Networks Ltd.",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7313,
    gpText: "Indigo Pipelines Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7314,
    gpText: "Veolia Water Outsourcing Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7315,
    gpText: "GeneSYS Telecommunications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7316,
    gpText: "PowerSystems UK Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7318,
    gpText: "Cross Rail Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7319,
    gpText: "Severn Trent Services Operations UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7320,
    gpText: "Luns Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7321,
    gpText: "Olympic Delivery Authority",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7322,
    gpText: "Rolta UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7324,
    gpText: "Internal Communication Systems Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7325,
    gpText: "EirGrid UK Holdings Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7326,
    gpText: "Independent Water Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7327,
    gpText: "NMCN plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7328,
    gpText: "Fujitsu Services Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7329,
    gpText: "Gigaclear Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7330,
    gpText: "CityFibre Metro Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7331,
    gpText: "Hibernia Atlantic (UK) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7332,
    gpText: "Fibrewave Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7333,
    gpText: "Dudgeon Offshore Wind Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7334,
    gpText: "(AQ) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7335,
    gpText: "Concept Solutions People Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7336,
    gpText: "Open Fibre Networks (Wholesale) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7337,
    gpText: "The Rochester Bridge Trust",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7338,
    gpText: "Eleclink Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7339,
    gpText: "Call Flow Solutions Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7341,
    gpText: "UK Broadband Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7342,
    gpText: "Harlaxton Energy Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7343,
    gpText: "Glide Business Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7344,
    gpText: "Subtopia Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7345,
    gpText: "Bazalgette Tunnel Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7346,
    gpText: "Bolt Pro Tem Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7347,
    gpText: "HS2 Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7348,
    gpText: "Energy Assets Pipelines Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7349,
    gpText: "Hyperoptic Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7350,
    gpText: "Broadband for the Rural North Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7351,
    gpText: "Grain Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7352,
    gpText: "Internet Connections Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7353,
    gpText: "NextGenAccess Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7354,
    gpText: "Arqiva Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7355,
    gpText: "Truespeed Communications Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7356,
    gpText: "Leep Networks (Water) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7357,
    gpText: "Eclipse Power Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7358,
    gpText: "Communications Infrastructure Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7359,
    gpText: "Energy Assets Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7360,
    gpText: "Bryn Blaen Wind Farm Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7361,
    gpText: "UK Power Distribution Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7362,
    gpText: "G.Network Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7363,
    gpText: "Wildcard UK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7364,
    gpText: "Community Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7365,
    gpText: "Quickline Communications Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7366,
    gpText: "MUA Electricity Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7367,
    gpText: "MUA Gas Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7368,
    gpText: "Fulcrum Electricity Assets Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7369,
    gpText: "County Broadband Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7370,
    gpText: "ITS Technology Group Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7371,
    gpText: "Cambridge Fibre Networks Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7372,
    gpText: "Airband Community Internet Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7373,
    gpText: "Box Broadband Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7374,
    gpText: "Harlaxton Gas Networks Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7375,
    gpText: "Toob Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7376,
    gpText: "Full Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7377,
    gpText: "IX Wireless Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7378,
    gpText: "Lightning Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7379,
    gpText: "Zzoomm Plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7380,
    gpText: "Icosa Water Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7381,
    gpText: "Open Network Systems Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7383,
    gpText: "Squire Energy Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7384,
    gpText: "Swish Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7385,
    gpText: "Last Mile Electricity Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7386,
    gpText: "F & W Networks Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7387,
    gpText: "Jurassic Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7388,
    gpText: "Netomnia Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7389,
    gpText: "Vorboss Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7390,
    gpText: "Telcom Infrastructure Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7391,
    gpText: "Internetty Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7392,
    gpText: "Broadway Partners Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7393,
    gpText: "Lonsdale Network Services Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7394,
    gpText: "Vattenfall Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7395,
    gpText: "VX Fiber Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7396,
    gpText: "Hen Beudy Services Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7397,
    gpText: "National Gas Transmission",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7398,
    gpText: "New Ash Green Village Association Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7399,
    gpText: "People's Fibre Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7500,
    gpText: "Optimal Power Networks",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7501,
    gpText: "Ranston Farm Partnership",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7502,
    gpText: "Optical Fibre Infrastructure Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7503,
    gpText: "Exascale Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7504,
    gpText: "My Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7505,
    gpText: "Ogi Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7506,
    gpText: "Leep Electricity Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7507,
    gpText: "Voneus Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7508,
    gpText: "Cloud HQ Didcot Fibre GP Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7509,
    gpText: "Lit Fibre Group Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7510,
    gpText: "Fibre Assets Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7511,
    gpText: "Factco",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7513,
    gpText: "Cellnex On Tower Uk Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7514,
    gpText: "Secure Web Services Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7515,
    gpText: "Wildanet Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7516,
    gpText: "Vispa Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7517,
    gpText: "Orbital Net Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7518,
    gpText: "Digital Infrastructure Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7519,
    gpText: "Westnetworks Innovations Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7520,
    gpText: "The Fibre Guys Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7521,
    gpText: "Railsite Telecom Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7522,
    gpText: "Nynet Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7523,
    gpText: "MS3 Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7524,
    gpText: "Lightspeed Networks Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7525,
    gpText: "Wessex Internet",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7526,
    gpText: "UPP (Corporation Limited)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7527,
    gpText: "BRSK Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7528,
    gpText: "Allpoints Fibre Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7529,
    gpText: "Spring Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7530,
    gpText: "Stix Internet Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7531,
    gpText: "Connexin Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7532,
    gpText: "Welink Communications UK Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7533,
    gpText: "Pine Media Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7534,
    gpText: "Persimmon Homes Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7535,
    gpText: "Boundless Networks Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7536,
    gpText: "Tiger Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7537,
    gpText: "Fibrus Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7538,
    gpText: "Grayshott Gigabit Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7539,
    gpText: "Freedom Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7540,
    gpText: "Exponential-E Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7541,
    gpText: "Axione Uk Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7542,
    gpText: "Ociusnet Uk Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7543,
    gpText: "Hampstead Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7544,
    gpText: "Affinity Systems Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7545,
    gpText: "AJ Technologies Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7546,
    gpText: "Yesfibre Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7547,
    gpText: "Boldyn Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7548,
    gpText: "Greenlink Interconnector Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7549,
    gpText: "Transport For Wales (TFW)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7550,
    gpText: "1310 Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7551,
    gpText: "Gofibre Holdings Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7552,
    gpText: "Allpoints Fibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7553,
    gpText: "Global Reach Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7554,
    gpText: "Fibrenation Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7555,
    gpText: "Indigo Power Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7556,
    gpText: "Open Infra Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7557,
    gpText: "EDF Energy Renewables Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7558,
    gpText: "Suez Advanced Solutions UL Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7559,
    gpText: "Latos Data Centre Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7560,
    gpText: "The Electricity Network Company Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7561,
    gpText: "Vattenfall Wind Power Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7562,
    gpText: "Nexfibre Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7563,
    gpText: "BAI Communications Infrastructure Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7564,
    gpText: "ESP Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7565,
    gpText: "Runfibre Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7566,
    gpText: "Orsted Hornsea Project Three (UK) Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7567,
    gpText: "Cornerstone Telecommunications Infrastructure Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7568,
    gpText: "Last Mile Telecom Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7569,
    gpText: "Riverside Energy Park Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7570,
    gpText: "Wifinity Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7571,
    gpText: "Squire Energy Metering Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7572,
    gpText: "Whyfibre Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7573,
    gpText: "Crosskit Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7574,
    gpText: "Zoom Internet Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7575,
    gpText: "Serverhouse Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7576,
    gpText: "X-Fibre Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7577,
    gpText: "Hendy Wind Farm Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7578,
    gpText: "Smartfibre Broadband Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7579,
    gpText: "E-Volve Solutions Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7580,
    gpText: "Fibrus Networks GB Ltd",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7581,
    gpText: "East Anglia Three Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7582,
    gpText: "Independent Distribution Connection Specialists Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 7583,
    gpText: "Advanced Electricity Networks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9000,
    gpText: undefined,
    osText: "Orkney Islands Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9010,
    gpText: undefined,
    osText: "Shetland Islands Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9020,
    gpText: undefined,
    osText: "Comhairle nan Eilean Siar (Western Isles Council)",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9051,
    gpText: undefined,
    osText: "City of Aberdeen Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9052,
    gpText: undefined,
    osText: "Aberdeenshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9053,
    gpText: undefined,
    osText: "Angus Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9054,
    gpText: undefined,
    osText: "Argyll & Bute Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9055,
    gpText: undefined,
    osText: "Scottish Borders Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9056,
    gpText: undefined,
    osText: "Clackmannanshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9057,
    gpText: undefined,
    osText: "West Dunbartonshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9058,
    gpText: undefined,
    osText: "Dumfries & Galloway Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9059,
    gpText: undefined,
    osText: "Dundee City Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9060,
    gpText: undefined,
    osText: "East Ayrshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9061,
    gpText: undefined,
    osText: "East Dunbartonshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9062,
    gpText: undefined,
    osText: "East Lothian Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9063,
    gpText: undefined,
    osText: "East Renfrewshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9064,
    gpText: undefined,
    osText: "City of Edinburgh Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9065,
    gpText: undefined,
    osText: "Falkirk Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9066,
    gpText: undefined,
    osText: "Fife Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9067,
    gpText: undefined,
    osText: "Glasgow Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9068,
    gpText: undefined,
    osText: "Highland Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9069,
    gpText: undefined,
    osText: "Inverclyde Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9070,
    gpText: undefined,
    osText: "Midlothian Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9071,
    gpText: undefined,
    osText: "Moray Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9072,
    gpText: undefined,
    osText: "North Ayrshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9073,
    gpText: undefined,
    osText: "North Lanarkshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9074,
    gpText: undefined,
    osText: "Perth & Kinross Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9075,
    gpText: undefined,
    osText: "Renfrewshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9076,
    gpText: undefined,
    osText: "South Ayrshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9077,
    gpText: undefined,
    osText: "South Lanarkshire Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9078,
    gpText: undefined,
    osText: "Stirling Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9079,
    gpText: undefined,
    osText: "West Lothian Council",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9080,
    gpText: "Queensferry Crossing",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9100,
    gpText: "AWG Group Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9101,
    gpText: "Northumbrian Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9102,
    gpText: "United Utilities Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9103,
    gpText: "Severn Trent Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9104,
    gpText: "Southern Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9105,
    gpText: "South West Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9106,
    gpText: "Thames Water Utilities Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9107,
    gpText: "Dwr Cymru Cyfyngedig (Welsh Water)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9108,
    gpText: "Wessex Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9109,
    gpText: "Yorkshire Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9110,
    gpText: "Bournemouth Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9111,
    gpText: "South West Water Limited (Formerly Bristol Water Plc)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9113,
    gpText: "Cambridge Water Plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9114,
    gpText: "Hafren Dyfrdwy Cyfyngedig (Formerly Chester Waterworks)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9115,
    gpText: "Cholderton and District Water Company Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9117,
    gpText: "South East Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9118,
    gpText: "SES Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9120,
    gpText: "Essex and Suffolk Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9121,
    gpText: "Affinity Water South East Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9122,
    gpText: "Hartlepool Water",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9123,
    gpText: "Mid Kent Water",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9124,
    gpText: "Mid Southern Water",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9126,
    gpText: "North East Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9127,
    gpText: "North Surrey Water",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9128,
    gpText: "Portsmouth Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9129,
    gpText: "South Staffordshire Water Plc",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9131,
    gpText: "Sutton District Water",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9132,
    gpText: "Affinity Water East Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9133,
    gpText: "Affinity Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9135,
    gpText: "Wrexham Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9136,
    gpText: "The York Waterworks Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9137,
    gpText: "Scottish Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9138,
    gpText: "Hafren Dyfrdwy Cyfyngedig",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9139,
    gpText: "Albion Water Limited",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9234,
    gpText: "Centro (West Midlands Passenger Transport Executive)",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9235,
    gpText: "Metro West Yorkshire Combined Authority",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9236,
    gpText: "South Yorkshire Passenger Transport Executive",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9998,
    gpText: "Street Managers without A Specific Code",
    osText: "Private Street Manager",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 9999,
    gpText: "Street Works Licences",
    osText: undefined,
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10010,
    gpText: undefined,
    osText: "Symology Ltd",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10020,
    gpText: undefined,
    osText: "Susiephone",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10100,
    gpText: undefined,
    osText: "Instalcom",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10110,
    gpText: undefined,
    osText: "Scottish Gas",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10120,
    gpText: undefined,
    osText: "Scottish Water",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10130,
    gpText: undefined,
    osText: "T Mobile",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10140,
    gpText: undefined,
    osText: "Wight Cable",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10150,
    gpText: undefined,
    osText: "WS Atkins",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10160,
    gpText: undefined,
    osText: "BP",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10180,
    gpText: undefined,
    osText: "Royal Mail",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10210,
    gpText: undefined,
    osText: "Atlantic Telecomms",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10220,
    gpText: undefined,
    osText: "GEO",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10230,
    gpText: undefined,
    osText: "Innovene",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10240,
    gpText: undefined,
    osText: "Independent Pipelines Limited",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10250,
    gpText: undefined,
    osText: "Energetics",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
  {
    id: 10260,
    gpText: undefined,
    osText: "Office of Road Works Commissioner",
    colour: adsLightGreyA,
    maintainingAuthority: false,
  },
];

export default SwaOrgRef;
